import React, { useEffect, useState } from "react";
import FieldWithLabel from "../../components/FieldWithLabel";
import ImageCropper from "../../components/ImageCropper";
import { useLoader } from "../../context/loaderContext";
import { Alert, Box, Button, FormLabel, Radio, RadioGroup, Snackbar, TextareaAutosize } from "@mui/material";
import { uploadFile } from "../../api/file";
import { getBanner, getVideoFoundation, setBannerData, setVideoFoundation } from "../../api/program";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUploaderSingle from "../FileUploaderSingle";

interface FormData {
  video: string;
  link?: string | undefined;
}

const validationSchema = Yup.object({
  video: Yup.string().defined(),
  link: Yup.string(),
});

export default function YayasanVideo() {
  const { showLoader, hideLoader } = useLoader();
  const [testimoniBanner, setTestimoniBanner] = useState<File | null>(null);
  const [previewTestimoniBanner, setPreviewTestimoniBanner] =
    useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("file")
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const defaultValues = {
    video: "",
    link: "",
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const watchedLink = watch("link")

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const res = await getVideoFoundation();
        if (res?.data?.video.includes("<iframe")) {
          setValue("link", res?.data?.video)
          setSelectedType("link")
        } else {
          setSelectedType("file")
          setPreviewTestimoniBanner(res.data?.video)
        }
      } catch (error) {
        console.error("Error fetching program:", error);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, [showLoader, hideLoader, setValue]);

  const handleFileChangeTestimoni = (data: any) => {
    if (!data) {
      return setPreviewTestimoniBanner("");
    }
    setTestimoniBanner(data?.file);
  };

  const onSubmit = async (form: any) => {
    showLoader();
    try {
      let video = ""
      if (testimoniBanner && testimoniBanner instanceof File && selectedType == "file") {
        try {
          const fD = new FormData();
          fD.append("file", testimoniBanner);

          const result = await uploadFile(fD);
          const path = result.data as string;
          video = path;
        } catch (error) {
          console.error("Error uploading file", error);
        }
      } else if (selectedType === "link" && form.link) {
        video = form.link
      }

      const res = await setVideoFoundation({ video: video });
      if (res.status) {
        setSnackbar({
          open: true,
          message: "Data berhasil diupdate.",
          severity: "success",
        });
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <div className="w-fit bg-white rounded-md p-4 px-6">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-y-4 mt-4"
        >
          <h1 className="text-xl font-bold">Video Profile Yayasan</h1>
          <RadioGroup value={selectedType} name="radio-buttons-group" onChange={(e: React.ChangeEvent<HTMLInputElement>, value: string) => setSelectedType(value)} row className="flex items-center">
            <Radio title="Upload File" value={"file"} name="Upload File" id="file" />
            <FormLabel htmlFor="file">
              Upload File
            </FormLabel>
            <Radio title="URL Link" value={"link"} id="link" />
            <FormLabel htmlFor="link">
              URL Link
            </FormLabel>
          </RadioGroup>
          {selectedType === "file" && <FieldWithLabel label="" applyMargin={true}>
            <Box className="flex flex-col gap-2 !w-full mb-4">
              <FileUploaderSingle
                onChange={handleFileChangeTestimoni}
                initialFile={previewTestimoniBanner}
              />
            </Box>
          </FieldWithLabel>}
          {selectedType == "link" && <FieldWithLabel label="">
            <TextareaAutosize
              className="mb-4 p-2  border-[.5px] !rounded-lg border-black"
              {...register("link")}
              placeholder="Embed Link Youtube"
            />
            {
              watchedLink && <div dangerouslySetInnerHTML={{ __html: watchedLink }} />
            }
          </FieldWithLabel>
          }
          <div className="flex justify-end mt-4">
            <Button
              className="!w-fit !capitalize"
              type="submit"
            >
              Simpan
            </Button>
          </div>
        </form>
      </div >
    </>
  );
}
