import { fetcher } from "./apiClient";

export const getProgram = () => {
  return fetcher("/cms/foundation/svm");
};

export const setProgram = async (data: any) => {
  return fetcher("/cms/foundation/svm", {
    method: "PUT",
    body: data,
  });
};

export const getBanner = () => {
  return fetcher("/cms/foundation/banner");
};

export const setBannerData = async (data: any) => {
  return fetcher("/cms/foundation/banner", {
    method: "PUT",
    body: data,
  });
};

export const getVideoFoundation = async () => {
  return await fetcher("/cms/foundation/video")
}

export const setVideoFoundation = async (data:any) => {
  return await fetcher("/cms/foundation/video",{
    method : "PUT",
    body : data,
  })
}
